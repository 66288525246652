import { FC, Fragment } from "react";
import { Button } from "../button";
import { Cross } from "../icons/cross";
import styles from './index.module.scss';

interface TariffsProps {
  onClose: () => void;
}

export const Tariffs: FC<TariffsProps> = ({onClose}) => {

  return (
    <Fragment>
      <div className={styles.modal_overlay} onClick={onClose} />
        <div className={styles.modal_container}>
          <div className={styles.modal_header}>
            <h4 className={styles.title}>Тарифы</h4>
            <button className={styles.cross_button} onClick={onClose}>
              <Cross className={styles.cross_icon} />
            </button>
          </div>
          <div className={styles.modal_content}>
            <table className={styles.table}>
              <thead>
              <tr>
                  <th>Вес отправления</th>
                  <th>Тариф с НДС</th>
              </tr>
              </thead>
                <tbody>
                <tr>
                    <td>до 0,5 кг</td>
                    <td>1,88 р.</td>
                  </tr>
                  <tr>
                    <td>0,51-1 кг</td>
                    <td>3,79 р.</td>
                  </tr>
                  <tr>
                    <td>1,01 - 2 кг</td>
                    <td>4,00 р.</td>
                  </tr>
                  <tr>
                    <td>2,01-5 кг</td>
                    <td>4,99 р.</td>
                  </tr>
                  <tr>
                    <td>5,01-10 кг</td>
                    <td>6,49 р.</td>
                  </tr>
                  <tr>
                    <td>10,01-15 кг</td>
                    <td>8,59 р.</td>
                  </tr>
                  <tr>
                    <td>15,01-20 кг</td>
                    <td>10,30 р.</td>
                  </tr>
                  <tr>
                    <td>20,01-25 кг</td>
                    <td>12,30 р.</td>
                  </tr>
                  <tr>
                    <td style={{border: "none"}}>25,01 - 30 кг</td>
                    <td style={{border: "none"}}>14,50 р.</td>
                  </tr>
                </tbody>
            </table>
          </div>
          <div className={styles.modal_footer}>
            <Button className={styles.close_button} size="large" type="secondary" text="Закрыть" onClick={onClose} />
          </div>
        </div>
    </Fragment>
  )
}