import { FC } from 'react';
import { Title } from '../../../title';
import styles from './index.module.scss';

const items = [
	'Пройдите несложную регистрацию',
	'Загрузите товары, установите цены и остатки',
	'Мы проводим быстрый процесс модерации',
	'Всё: публикуйте товары и продавайте!',
];

export const Selling: FC = () => {
	return (
		<section className={styles.mission}>
			<figure className={styles.top}>
				<figcaption className={styles.left}>
					<Title title="Как начать продавать?" />
					<p className={styles.text}>
					Нужно желание и статус юр. лица или ИП, зарегистрированного в Республике Беларусь. Потребуется всего несколько дней
					и 33 рубля для оплаты регистрации поддомена, который будет присвоен вашему магазину на emall
					</p>
				</figcaption>
				<img src="/assets/images/busel-how-to-sell.png?v=1" alt='Как начать продавать' className={styles.image} />
			</figure>
			<div className={styles.bottom}>
				{items.map((title, index) => (
					<div key={index} className={styles.item}>
						<span className={styles.count}>{index + 1}</span>
						<span className={styles.title}>{title}</span>
					</div>
				))}
			</div>
		</section>
	);
};
