/* eslint-disable no-irregular-whitespace */
import clsx from 'clsx';
import cls from '../../../../pages/faq/index.module.scss';
import {columnsUploadGoods, columnsWorksImages, dataSourceUploadGoods, dataSourceWorksImages} from './tableData';
import {Icon} from 'ui-kit-euroopt';
import {Table} from 'antd';
import {config} from "../../../../config";
import {openModalFeedback} from "../../../modals/feedbackModal";

export interface IChildAccordion {
	title: string;
	subTitle: string;
	img?: string;
	content: (openModalFeedback?: (value: boolean) => void) => any;
}

export interface IDataSidebar {
	key: string;
	label: string;
	items?: IChildAccordion[];
	bgColor?: string;
}

const BlockInfo = (content: string): any => (
	<div className={cls.blockInfo}>
		<Icon name="info-20" />
		<span>{content}</span>
	</div>
);

const FeedbackContent = () => {
	const onClick = () => openModalFeedback(false)

	return <span className={cls.link} onClick={onClick}>форму обратной связи</span>
}

const GetImage = (img: string): any => {
	return <img src={`/assets/images/faq/${img}`} alt="Картинка" />;
};

const dataFAQ: IDataSidebar[] = [
	{
		key: '0',
		label: 'База знаний',
	},
	{
		key: '1',
		label: 'Начало работы',
		bgColor: 'gray',
		items: [
			{
				title: 'Регистрация на площадке',
				subTitle: 'Несложный процесс, но инструкцию почитать стоит',
				content: (): JSX.Element => (
					<>
						<h2>Регистрация на площадке</h2>
						<h3>
							Шаг 1. Перейдите по{' '}
							<a href="https://b2b.emall.by" target="_blank" rel="noreferrer">
								ссылке
							</a>{' '}
							и корректно заполните первичные данные
						</h3>
						<p>
							Введите УНП и нажмите кнопку <strong>Продолжить.</strong>
						</p>
						<p>
							После проверки введенного УНП, будут автоматически предзаполнены некоторые поля. Оставшиеся
							обязательные
							поля заполните вручную.{' '}
						</p>
						{BlockInfo(
							'В последующем вносить правки в данные о Продавце будет возможно только через связь с Технической Поддержкой',
						)}
						<p>
							Перечень полей, которые будут отображаться на вашей личной странице после регистрации и
							будут доступны к
							просмотру всем пользователям сервиса:
						</p>
						<ul>
							<li>полное наименование ЮЛ;</li>
							<li>УНП;</li>
							<li>дата регистрации ЮЛ или ИП;</li>
							<li>юридический и почтовый адреса;</li>
							<li>контактные данные ЮЛ для связи (телефон и Email);</li>
							<li>режим работы;</li>
							<li>
								номера контактных телефонов местных распорядительных органов по месту регистрации
								продавца,
								уполномоченных рассматривать обращения покупателей;
							</li>
							<li>дата включения ЮЛ или ИП в торговый реестр РБ;</li>
							<li>номер расчетного счета;</li>
							<li>наименование банка и его БИК.</li>
						</ul>

						{BlockInfo(
							'support@emall.by – это общая почта Команды заботы о партнёрах. Команда пообещала решать все ваши вопросы в течение 24 часов с момента обращения, а если будет получаться, то и быстрее.',
						)}
						<h3>Шаг 2. Убедитесь, что приняли условия двух публичных договоров</h3>
						<p>
							Для того, чтобы продавать на площадке emall.by необходимо дать согласие с условиями двух
							публичных
							договоров:
						</p>
						<ul>
							<li>
								<a href={`${config.api.url}/emall_agreement.pdf`} target="_blank" rel="noreferrer">
									<strong>Договор присоединения</strong>
								</a>
								. Регулирует правила и условия продажи товаров продавца на площадке. На основании этого
								договора
								Продавец оплачивает вознаграждение.
							</li>
							<li>
								<a href={`${config.api.url}/postal_agreement.pdf`} target="_blank" rel="noreferrer">
									<strong>Договор оказания услуг почтовой связи</strong>
								</a>
								. Регулирует условия и правила использования сети отделений Европочта в качестве пунктов
								выдачи, а также
								условия и правила организации курьерской доставки товаров продавца. На основании этого
								договора Продавец
								оплачивает услугу доставки и выдачи товара покупателю.
							</li>
						</ul>
						<p>Для присоединения к договорам нужно проставить соответствующие отметки на странице
							регистрации.</p>
						{GetImage('registration2.png')}
						<h3>Шаг 3. Ожидайте регистрацию доменного имени вашего магазина</h3>
						<p>
							Мы берем на себя процедуру регистрации в РУП «БелГИЭ» присвоенного вам доменного имени
							(поддомена). Для
							запуска процедуры регистрации, пожалуйста, оплатите счёт (его можно скачать в личном
							кабинете).
						</p>
						<p>Стоимость регистрации поддомена на 01.01.2025: 33 руб.;</p>
						<p>Ориентировочные сроки регистрации поддомена: 15 календарных дней.</p>
						<h3>Шаг 4. Зарегистрируйте доменное имя вашего магазина в торговом реестре Республики
							Беларусь</h3>
						<p>Доменное имя вашего магазина скопируйте в адресной строке личного кабинета.</p>
						{GetImage('registration1.png')}
						<p>
							Подайтесь на регистрацию самостоятельно.{' '}
							<a href={`${config.api.url}/registration_in_tr_rb.pdf`} target="_blank" rel="noreferrer">
								Инструкция
							</a>
							<br />
							<br />
							Регистрация бесплатная.
							<br />
							<br />
							Ориентировочные сроки регистрации: до 5 рабочих дней.
						</p>
						{/*<p>*/}
						{/*  <strong>Если остались вопросы</strong>*/}
						{/*</p>*/}
						{/*<p>Общие вопросы по процессу регистрации - 8 029 000 00 00 </p>*/}
						{/*<p>Европочта. Вопросы по тарифам - 8 029 000 00 00</p>*/}
					</>
				),
			},
			{
				title: 'Настройка работы магазина',
				subTitle: 'Как делается и на что влияет',
				content: () => (
					<>
						<h2>Настройка работы магазина</h2>
						<p>
							Сделайте предварительные настройки работы вашего магазина. Для этого перейдите в раздел{' '}
							<a href="#">Настройки → Календарь отгрузок.</a>
						</p>
						{GetImage('setingsWorks.png')}
						<p>
							Для того, чтобы вы могли обеспечивать максимально высокое качество работы с заказами –
							установите свои
							внутренние стандарты в Календаре отгрузок:
						</p>
						<ol>
							<li>Определите ОПС отгрузки*. При необходимости, его можно в любой момент поменять.</li>
							<li>
								Определите максимальное время с момента поступления нового заказа, за которое вы сможете
								доставить его в
								ОПС отгрузки.
							</li>
							<li>
								Настройте рабочие/выходные дни – дни, по которым осуществляется и не осуществляется
								приём и отгрузка
								заказов.
							</li>
						</ol>
						{BlockInfo(
							'Данная настройка будет применяться в расчете прогнозируемой даты доставки товара, которую увидит покупатель.',
						)}
						<p>
							<strong>*ОПС отгрузки –</strong> это Отделение Почтовой Связи Европочта, куда вы доставите
							собранный
							заказ, который затем Европочта доставит до заказчика.
						</p>
						<h3>Итак, вы ожидаете</h3>
						<ol>
							<li>Регистрации в РУП «БелГИЭ»;</li>
							<li>Регистрации в Торговом реестре РБ.</li>
						</ol>
						<p>
							В это время ваш личный кабинет уже открыт и вы уже можете наполнять свой магазин. Загрузите
							ассортимент
							товаров и качественный контент для каждого товара. Установите цены. <a
							href="#">Инструкция</a>
						</p>
					</>
				),
			},
			{
				title: 'Причины блокировки магазина',
				subTitle: 'Причины блокировки и как возобновить работу',
				content: () => (
					<>
						<h2>Причины блокировки магазина</h2>
						<p>
							Если вы нарушаете условия работы на площадке, мы вправе заблокировать работу вашего
							магазина. Блокировака
							означает, что ваш товар не отображается на сайте. Чтобы восстановить работу и вновь начать
							продавать,
							необходимо устранить причину блокировки.
						</p>
						<h3>Причины блокировки </h3>
						<ul>
							<li>У вас есть задолженность по оплате услуг площадки (услуг по доставке товаров и комиссии
								маркетплейса). Важно осуществить оплату не позднее 10-го числа месяца, который следует
								за отчётным периодом, чтобы избежать автоматической блокировки.
							</li>
							<li>
								Вы не соблюдаете условия договора с площадкой. В частности допущены нарушения условия по
								пунктам
								договора присоединения №14 от 22.12.2023, п.п. 2,17; 2.18; 3.8; 3.9; 3.11; 4.2; 7.10
							</li>
							<li>Доля отмен товаров в заказах по вашей инициативе + автоотмен превышает 15%</li>
						</ul>
						<h3>Как восстановить работу</h3>
						<ul>
							<li>
								Внести оплату за оказанные услуги и написать нам на <FeedbackContent />.
							</li>
							<li>
								Устранить допущенные нарушения и сообщить об этом представителю emall письмом на почту
								support@emall.by
							</li>
							<li>
								Снизить влияние фактора, провоцирующего отмены (вывести из ассортимента товары, которые
								часто отменяете,
								изменить настройки работы магазина в отношении скорости сборки заказа и т.п.). После
								внесения изменений
								- написать нам на <FeedbackContent />.
							</li>
						</ul>
					</>
				),
			},
			{
				title: 'Оплата за регистрацию поддомена',
				subTitle: 'Как сделать так, чтобы всё прошло быстро и гладко',
				content: () => (
					<>
						<h2>Оплата за регистрацию поддомена</h2>
						<p>
							В личном кабинете у вас загружен счёт. В нем вы найдёте УНП получателя, который потребуется
							для оплаты, и
							сумму. Также вы получите этот счёт на почту. На основании этого счёта вы сможете составить
							единоличный акт.
						</p>
						<p>Просим вас соблюдать два простых правила оплаты:</p>
						<ul>
							<li>оплачивайте только с расчетного счёта, который указали при регистрации магазина;</li>
							<li>оплачивайте всю сумму сразу, не делите на части.</li>
						</ul>
						<p>В таком случае процесс ожидания займёт не более суток. И вы быстро сможете начать
							продавать.</p>
						<p>
							Если вы произвели оплату с другого счёта или с карты физлица, мы не сможем принять оплату в
							таком виде.
							Чтобы принять её:
						</p>
						<ul>
							<li>Мы свяжемся с вами и вышлем вам форму заявления на зачисление оплаты;</li>
							<li>Вы потратите время на заполнение заявления и отправку нам скан-копии.</li>
						</ul>
						<p>
							После всего этого оплата сможет быть зачтена, однако сроки ожидания начала продаж
							естественным образом
							увеличатся.
						</p>
					</>
				),
			},
			{
				title: 'Кто может стать продавцом',
				subTitle: 'Есть временные ограничения для самозанятых',
				content: () => (
					<>
						<h2>Кто может стать продавцом</h2>
						<p>На сегодняшний день на emall.by могут продавать товар:</p>
						<ul>
							<li>юридические лица, зарегистрированные в РБ;</li>
							<li>индивидуальные предприниматели, зарегистрированные в РБ.</li>
						</ul>
						<p>
							Мы работаем над тем, чтобы дать возможность самозанятым, ремесленникам и физическим лицам
							присоединиться к
							нам в качестве партнеров. На это потребуется некоторое время. Для отслеживания новостей
							подпишитесь на{' '}
							<a href="https://t.me/emallby" target="_blank" rel="noreferrer">
								официальный аккаунт
							</a>{' '}
							emall для продавцов.
						</p>
					</>
				),
			},
		],
	},
	{
		key: '2',
		label: 'Работа с товарами',
		bgColor: 'pink',
		items: [
			{
				title: 'Загружаем ассортимент',
				subTitle: 'Есть несколько способов - выбирайте, какой удобнее',
				content: () => (
					<>
						<h2>Загружаем ассортимент</h2>
						<h3>Шаг 1. Создайте карточки товаров</h3>
						<p>
							Для этого вам нужен раздел личного кабинета <a href="#">Список товаров</a>
						</p>
						<p>
							В зависимости от товарной категории, карточка товара имеет определённые поля, при заполнении
							которых
							характеристики товара будут описаны максимально полно.
						</p>
						<p>У вас есть два способа загрузки данных:</p>
						<ol>
							<li>Создание каждого товара вручную;</li>
							<li>Загрузка данных из Excel;</li>
						</ol>
						{BlockInfo('В ближайшее время будет добавлена возможность загружать товары посредством API интеграции.')}
						<div className={clsx(cls.blockInfo, cls.blockInfo__gold)}>
							<Icon name="info-20" />
							<span>
								Заполняйте корректно все поля, распределяйте товары в правильные категории – это влияет на результаты
								поиска.
							</span>
						</div>
						<h3>Шаг 2. Отправьте товары на модерацию</h3>
						<p>
							На этом этапе мы проверяем, что информация о товаре и все характеристики соответствуют
							требованиям. Это
							может занимать некоторое время.
						</p>
						<ul>
							<li>Если товар создан в ручном режиме, то он отправляется на модерацию нажатием кнопки
								Отправить.
							</li>
							<li>Если товар загружен шаблоном, то отправка на модерацию осуществляется автоматически.
							</li>
						</ul>
						<p>
							Товары отображаются в блоке <a href="#">Товары и цены → Список товаров</a> и имеют статусы:
						</p>
						<Table
							className={cls.table}
							pagination={false}
							dataSource={dataSourceUploadGoods}
							columns={columnsUploadGoods}
						/>
						<h3>Шаг 3. Редактируйте товары по запросу emall или по собственной инициативе</h3>
						<p>Два способа редактирования:</p>
						<ol>
							<li>
								Вручную. Доступно с помощью контекстного меню (три точки) в правой части <a href="#">списка
								товаров</a>; Если ваш товар уже "В продаже", то необходимо "Остановить продажи" - после
								этого появится возможность его редактирования.
							</li>
							<li>
								Перезагрузка шаблона Excel. Просто загрузите новый файл Excel на{' '}
								<a href="#">странице обновления цен и остатков</a>. Новые данные загрузятся поверх
								старых. Товары, по
								которым не было изменений, останутся в первоначальном виде.
							</li>
						</ol>
						<p>
							После каждого редактирования необходимо повторно отправить товар на модерацию. Пока идет
							повторная
							модерация, товар отображается в первоначальном виде.
						</p>
						<h3>Шаг 4. Загрузите цены и остатки</h3>
						<p>
							Для этого вам нужен раздел личного кабинета <a href="#">Товары и цены</a>
						</p>
						<p>У вас есть два способа загрузки и редактирования данных:</p>
						<ol>
							<li>Внесение вручную;</li>
							<li>Загрузка данных из Excel.</li>
						</ol>
						<p>Для загрузки данных из Excel:</p>
						<ul>
							<li>Скачайте шаблон в разделе «Товары и Цены». Он единый для всех категорий товаров;</li>
							<li>Заполните шаблон;</li>
							<li>Загрузите заполненный файл Excel.</li>
						</ul>
						<h3>Отображение товаров</h3>
						<p>
							Если вы не находите свой товар в каталоге, сверьтесь по чек-листу. Если хотя бы один из
							пунктов не
							выполняется, товар в каталоге отображаться не будет:
						</p>
						<ul>
							<li>Проставлено согласие с условиями Публичного договора присоединения;</li>
							<li>Проставлено согласие с условиями Публичного договора оказания почтовых услуг;</li>
							<li>Доменное имя зарегистрировано в РУП «БелГИЭ»;</li>
							<li>Доменное имя зарегистрировано в Торговом Реестре РБ;</li>
							<li>Расчетный остаток по товару (с учетом оформленных, но пока не доставленных заказов)
								больше 0;
							</li>
							<li>Статус товара – «В продаже».</li>
						</ul>
						<h3>Как снять товар с продажи</h3>
						<p>
							Если вам нужно временно приостановить отображение карточек товаров на сайте, нужно найти
							в{' '}
							<a href="#">списке товаров</a> нужную позицию и в контекстном меню (три точки) и выбрать
							пункт «Остановить
							продажи». Затем во всплывающем окне подтвердить перенос товара в архив. Товар не будет
							отображаться на
							сайте, но вы сможете восстановить его при необходимости.
						</p>
						<h3>Итак, у вас уже</h3>
						<ul>
							<li>Созданы карточки товаров (загружен ассортимент товаров);</li>
							<li>Присвоены цены;</li>
							<li>Загружены остатки.</li>
						</ul>
						<p>
							Если все регистрации первого этапа получены, а карточки товаров прошли модерацию – ваши
							товары
							отображаются на сайте и доступны к заказу.
						</p>
						{/*<p>*/}
						{/*  <strong>Если остались вопросы</strong>*/}
						{/*  <br />*/}
						{/*  <br />*/}
						{/*  Не скачиваются или не загружаются шаблоны - 8 029 000 00 00 <br />*/}
						{/*  <br />*/}
						{/*  Не отображается карточка товара - 8 029 000 00 00*/}
						{/*  <br />*/}
						{/*  <br />*/}
						{/*  Не понятны замечания после модерации – 8 029 000 00 00*/}
						{/*  <br />*/}
						{/*  <br />*/}
						{/*  Общие вопросы по процессу создания карточек товаров, загрузки цен*/}
						{/*  и остатков – 8 029 000 00 00*/}
						{/*</p>*/}
					</>
				),
			},
			{
				title: 'Причины блокировки магазина',
				subTitle: 'Что-то временно, а что-то постоянно',
				content: () => (
					<>
						<h2>Причины блокировки магазина</h2>
						<p>По умолчанию, мы с вами действуем в рамках требований двух нормативных документов:</p>
						<ol>
							<li>
								Правила продажи товаров при осуществлении розничной торговли по образцам{' '}
								<a href="#">
									<Icon name="download" mix={cls.downloadIcon} />
								</a>
							</li>
							<li>
								Требования к оказанию услуг почтовой связи{' '}
								<a href="#">
									<Icon name="download" mix={cls.downloadIcon} />
								</a>
							</li>
						</ol>
						<p>
							<strong>Бессрочный запрет на размещение на площадке действует на:</strong>
						</p>
						<ul>
							<li>
								предметы, которые по своим свойствам или характеру упаковки могут представлять опасность
								для работников
								оператора почтовой связи, привести к загрязнению или порче других почтовых отправлений
								или почтового
								оборудования;
							</li>
							<li>скоропортящиеся продукты питания;</li>
							<li>табак, алкоголь;</li>
							<li>
								электронные системы курения, жидкости для электронных систем курения, системы для
								потребления табака;
							</li>
							<li>ювелирные и другие бытовые изделия, сусального золота и сусального серебра, драгоценных
								камней;
							</li>
							<li>лекарственные средства;</li>
							<li>специализированную пищевую продукцию для питания спортсменов;</li>
							<li>ветеринарные средства;</li>
							<li>семена мака;</li>
							<li>биологические вещества;</li>
							<li>радиоактивные вещества;</li>
							<li>сильнодействующие, ядовитые, легковоспламеняющиеся, взрывчатые и другие опасные
								вещества;
							</li>
							<li>животные и ядовитые растения;</li>
							<li>
								иностранная валюта (кроме памятных и слитковых (инвестиционных) монет, принятых
								национальным оператором
								почтовой связи от банков и небанковских кредитно-финансовых организаций Республики
								Беларусь);
							</li>
							<li>
								печатные и аудиовизуальные материалы, иные носители информации, содержащие сведения,
								которые могут
								причинить вред политическим или экономическим интересам Республики Беларусь, ее
								государственной
								безопасности, охране здоровья и нравственности граждан;
							</li>
							<li>боевое, гражданское оружие и боеприпасы к нему;</li>
							<li>
								наркотические средства, психотропные вещества, их прекурсоры и аналоги, в том числе
								лекарственные
								препараты их содержащие.
							</li>
						</ul>
						<p>
							<strong>Временно невозможно разместить на площадке для продажи:</strong>
						</p>
						<ul>
							<li>одежду, требующую примерки;</li>
							<li>крупногабаритные товары (вес более 30кг; габариты более 250 см по длине трех сторон).
							</li>
						</ul>
					</>
				),
			},
			{
				title: 'Создаем карточку товара',
				subTitle: 'Что нужно, чтобы попадать в результаты поиска, "похожие" и хорошо продаваться',
				content: () => (
					<>
						<h2>Создаем карточку товара</h2>
						<p>
							Базовое правило – чем лучше описан товар, тем выше вероятность его покупки. Изучите наши
							рекомендации по
							заполнению карточек, чтобы по максимуму использовать возможности площадки.
						</p>
						<h3>Выбор категории товара</h3>
						<ul>
							<li>
								Если у вас есть сомнения, в какую категорию отнести товар – попробуйте найти товар с тем
								же типом на
								сайте, чтобы узнать, через какую категорию продаем его мы.
							</li>
							<li>
								Когда вы определяете товар в категорию – выбранная категория определяет, какие поля
								будут доступны для
								описания характеристик/свойств товара. Например, если разместить телевизор в категорию
								«Проекторы» -
								свойства на вашем телевизоре будут для проектора, а значит и описать его корректно не
								получится.
							</li>
							<li>
								Если вы не находите нужной категории, вы считаете, что доступная категория слишком общая
								и необходимо разделение,
								в выбранной вами категории не хватает критично важных полей для описания свойств товара,
								вы можете подать заявку на её заведение через <FeedbackContent />.
							</li>
						</ul>
						<h3>Описание товара</h3>
						<p>
							«Описание товара» – специальное поле, где вы можете разместить текст, указать лучшие стороны
							товара,
							привести примеры использования, основные отличительные особенности, расхвалить без
							ограничений.
							Естественно, в нормах цензуры сайта, в соответствии с законодательством РБ и не более 8000
							символов.
						</p>
						<p>
							В «Описание товара» нельзя добавлять ссылки, а также рекомендуем корректно интегрировать
							синонимы, если вы
							намерены это делать. Не перечисляйте массив синонимов через запятую, намного более полезно
							интегрировать
							их в текст фразами.
						</p>
						<h3>Как работает внутренний поиск</h3>
						<p>Мы не опишем все сложные алгоритмы, но вот, что вы должны учитывать:</p>
						<ul>
							<li>
								Чем больше полей заполнено – тем больше информации для анализа у поисковой системы.
								Поэтому заполняйте
								все предложенные поля;
							</li>
							<li>Для ранжирования товара ключевой вес имеют: название товара, бренд, тип товара,
								категория товара;
							</li>
							<li>
								Наша поисковая система сама подберет синонимы для ваших товаров. Например, если у вас в
								названии звучит
								«абрикос сушеный», то по поисковому запросу «курага» товар также будет попадать в
								результаты поиска;
							</li>
							<li>
								Вся поисковая система нацелена на повышение релевантности результатов. Система
								определяет, какие товары
								смотрят/кликают покупатели после отображения результатов поиска, и эти товары для этого
								запроса
								показывает чаще.
							</li>
						</ul>
						{BlockInfo(
							'Внутренний поиск имеет особенность. Возможность найти товар по любому признаку через внутренний поиск появляется через 4-6 часов после перевода товара в статус «В продаже». Поэтому проверку отображения следует осуществлять путем просмотра по прямой ссылке https://emall.by/product/0000000, где вместо нулей необходимо подставить артикул товара (начинается с цифры 9).',
						)}
						<h3>Название товара</h3>
						<p>Название товара – ключевая характеристика, которая:</p>
						<ul>
							<li>Объясняет покупателю что это и какое это;</li>
							<li>Имеет ключевой вес для внутренней поисковой системы на сайте.</li>
						</ul>
						<p>
							То есть, название товара должно быть составлено так, чтобы покупатель через внутренний поиск
							находил его
							по ключевым запросам.
						</p>
						<p>Наши рекомендации по составлению названия товара. Типовая схема: </p>
						<p>
							<strong>Тип + Бренд + Характеристики + Ключевой параметр.</strong>
						</p>
						<ul>
							<li>
								<strong>Тип</strong> – отвечает на вопрос «что это такое?» и отражает суть товара.
							</li>
							<li>
								<strong>Бренд</strong> – наличие его в наименовании позволяет сузить круг поиска по
								сайту и быстрее
								найти товар.
							</li>
							<li>перечисляются через запятую.</li>
							<li>
								<strong>Ключевой параметр</strong> – параметр, отличающий от товаров в линейке или любой
								другой,
								существенно влияющий на поиск или оценку товара покупателем. К примеру, вес, объем,
								модель, год издания.
							</li>
						</ul>
					</>
				),
			},
			{
				title: 'Штрихкод товара',
				subTitle: 'Что делать, если его нет',
				content: () => (
					<>
						<h2>Штрихкод товара</h2>
						<ul style={{listStyleType: 'none', padding: 0}}>
							<li>При заполнении карточки товара, вам потребуется внести данные в поле «Штрихкод».</li>
							<li>Если у товара есть штрихкод производителя, то вносите именно его.</li>
							<li>
								Если у вашего товара нет штрихкода, то сгенерируйте его в любом доступном вам
								онлайн-генераторе
								штрихкодов. Например, здесь{' '}
								<a href="https://barcode.tec-it.com/ru/EAN13" target="_blank" rel="noreferrer">
									https://barcode.tec-it.com/ru/EAN13
								</a>
								. Подходящие форматы: EAN8, EAN13.
							</li>
							<li>
								Если вы создаете карточки-наборы (несколько штук одного и того же товара), то для
								наборов также
								необходимо генерировать новый штрихкод.
							</li>
						</ul>
					</>
				),
			},
			{
				title: 'Работа с изображениями',
				subTitle: 'Как загрузить фото в карточку товара',
				img: 'camera-and-pictures.png',
				content: () => (
					<>
						<h2>Работа с изображениями</h2>
						<Table
							className={cls.table}
							dataSource={dataSourceWorksImages}
							columns={columnsWorksImages}
							pagination={false}
						/>
						{BlockInfo(
							'Качество и содержание изображений проходит модерацию, по итогам которой товар или допускается к отображению на сайте или нет.',
						)}
						<div className={cls.wrapperBlockSecondary}>
							<p className={cls.iconStandart}>&#9989;</p>
							<h3>Изображения пройдут модерацию, если:</h3>
						</div>
						<ul>
							<li>Фото в хорошем качестве;</li>
							<li>Товар на фото находится фокусе;</li>
							<li>Фото соответствует названию и описанию товара;</li>
							<li>Фото цветное;</li>
							<li>Фото не перевернуто.</li>
						</ul>
						<div className={cls.wrapperBlockSecondary}>
							<p className={cls.iconStandart}>&#10060;</p>
							<h3>Изображения НЕ пройдут модерацию, если:</h3>
						</div>
						<ul>
							<li>Содержат изображение алкоголя, сигарет;</li>
							<li>Они размытые и некачественные;</li>
							<li>Сделаны в «домашних условиях»: на фоне ковра, мебели с тенями от других предметов;</li>
							<li>Содержат надписи с указанием цен, скидок, контактных данных, ссылок на сторонние
								ресурсы;
							</li>
							<li>Содержат призывы к действию — например, позвонить менеджеру, сравнить товар, сделать
								покупку;
							</li>
							<li>Не соответствуют реальному товару;</li>
							<li>Содержат неприемлемый контент, демонстрирующий насилие или разжигающий вражду;</li>
							<li>
								Содержат изображения, которые признаны запрещенными к публикациям в соответствии с
								действующим
								законодательством;
							</li>
							<li>На главном и дополнительном фото - разные товары.</li>
						</ul>
						<h3>Товары 18+</h3>
						<p>Требования распространяются на все фотографии товара: главное изображение и
							дополнительные.</p>
						<p>
							На изображениях должны быть размыты или скрыты другим образом интимные части тела. Например,
							изображения
							гениталий, женской груди, ягодиц и игрушек, которые имитируют половые органы. Требования
							распространяются
							на фотографии товаров, людей и изображения на упаковке.
						</p>
						<h3>Ссылки на изображения</h3>
						<p>Если вы загружаете ассортимент через Шаблон, вам нужны будут ссылки на фотографии.</p>
						<p>Для загрузки главного фото есть отдельное поле, в которое можно добавить только одну прямую
							ссылку. </p>
						<p>
							Для загрузки всех дополнительных фото используется одно общее поле. В нем можно перечислять
							ссылки на
							каждое фото через точку с запятой (без пробела, максимум 10 ссылок) либо можно использовать
							ссылку на
							папку с изображениями на Яндекс.Диске.
							Например, https://yadi.sk/d/qUXcCwzmryWINw или https://disk.yandex.ru/d/qUXcCwzmryWINw.
						</p>
						<p>
							По ссылкам должны открываться и скачиваться только сами изображения. Обычно такие ссылки
							заканчиваются
							символами .jpeg, .jpg, .png. Исключение — ссылки на Яндекс.Диск. Они доступны в формате
							https://yadi.sk/i/qUXcCwzmryWINw или https://disk.yandex.ru/i/qUXcCwzmryWINw.
						</p>
						<p>
							<strong>Яндекс.Диск</strong>
						</p>
						<ul>
							<li>Выберите фото или папку с изображениями.</li>
							<li>Нажмите Поделиться.</li>
							<li>В открывшемся окне нажмите Скопировать ссылку.</li>
						</ul>
						<p>Пример ссылки: https://disk.yandex.ru/i/6d9LMNjAbwWlLg</p>
						{GetImage('workWithImages.png')}
						<p>
							<strong>Сайт-фотохостинг, например Postimage</strong>
						</p>
						<ol>
							<li>
								На главной странице <a href="#">postimg.cc</a> загрузите фото.
							</li>
							<li>
								Скопируйте ссылку в блоке <strong>Прямая ссылка.</strong>
							</li>
						</ol>
						<p>Пример ссылки: https://i.postimg.cc/DzzFbKG4/images.jpg</p>
					</>
				),
			},
			{
				title: 'Создаем варианты товара',
				subTitle: 'Зачем это нужно и как это сделать',
				img: 'pictures.png',
				content: () => (
					<>
						<h2>Создаем варианты товара</h2>
						<p>
							Вы создали карточку, а в вашем ассортименте есть похожие товары, которые отличаются
							одним-двумя
							признаками.
						</p>
						<p>
							Используйте существующую карточку + функцию «Создать вариант», чтобы создать остальные
							карточки
							максимально быстро – большинство общих характеристик уже будут предзаполнены.
						</p>
						<h3>Как это сделать</h3>
						<ol>
							<li>
								В разделе <a href="#">Список товаров</a> выберите товар, на основании которого хотите
								создать вариант;
							</li>
							<li>В контекстном меню (три точки) выберите «Создать вариант»;</li>
							<li>Заполните характеристики, по которым новый товар отличается от того, который вы взяли за
								основу.
							</li>
						</ol>
						<p>
							Созданные варианты будут автоматически объединены в группу. То есть, товары будут
							отображаться на одной
							карточке, внутри которой можно будет переключаться между вариативными признаками (размер,
							цвет и т.п.). В
							товарной категории все варианты товара будут видны как отдельные карточки. В учёте и
							аналитике – как
							отдельные карточки. В рамках объединённой группы вариантам товара может быть установлена
							разная цена.
						</p>
					</>
				),
			},
			{
				title: 'Объединяем товары',
				subTitle: 'Как объединить и, не менее важно, разъединить товары',
				content: () => (
					<>
						<h2>Объединяем товары</h2>
						<p>
							Опция полезна, если товары аналогичны, но отличаются только размером, цветом, ароматом или
							иными
							вариативными признаками.
						</p>
						<p>
							Объединяя варианты товара в группу, вы поможете покупателю быстрее определиться с покупкой.
							Объединить
							можно товары одной модели, одной категории, одного бренда. Один товар может находиться
							только в одной
							группе объединенных товаров.
						</p>
						<h3>Как объединить?</h3>
						<p>
							Нужно в <a href="#">Списке товаров</a> выбрать карточки, которые хотите объединить, и в
							появившемся меню
							снизу выбрать соответствующую команду.
						</p>
						{BlockInfo('Объединять можно только товары с одинаковой категорией и брендом.')}
						<h3>Как разъединить?</h3>
						<p>
							Чтобы удалить товар из группы объединенных товаров, воспользуйтесь контекстным меню (три
							точки) и выберите
							команду «Разъединить».
						</p>
					</>
				),
			},
			{
				title: 'Статусы карточки товара',
				subTitle: 'Какие бывают и что каждый из них значит',
				content: () => (
					<>
						<h2>Статусы карточки товара</h2>
						<Table
							pagination={false}
							className={cls.table}
							dataSource={dataSourceUploadGoods}
							columns={columnsUploadGoods}
						/>
					</>
				),
			},
			{
				title: 'Цена товара',
				subTitle: 'Виды цен, отображение на сайте, история изменений',
				img: 'bills.png',
				content: () => (
					<>
						<h2>Цена товара</h2>
						<p>У вас есть 2 вида цен, которые отображаются для покупателя:</p>
						<p>
							<strong>Текущая цена</strong>
						</p>
						<ul>
							<li>Это цена, по которой товар сейчас продаётся;</li>
							<li>Обязательное поле – если «Текущая цена» не введена, товар не будет отображаться на
								сайте.
							</li>
						</ul>
						<p>
							<strong>Цена до скидки</strong>
						</p>
						<ul>
							<li>Это цена, по которой товар продавался ранее;</li>
							<li>Не может быть меньше «Текущей цены»;</li>
							<li>Необязательное поле (может быть незаполненным).</li>
						</ul>
						<p>Во время расчёта цены на товар, рекомендуем вам воспользоваться калькулятором на <a
							target="_blank"
							href="http://localhost:3000#calc">сайте</a>, блок "Давайте посчитаем".</p>
					</>
				),
			},
			// {
			//   title: "Модерация карточек товаров -",
			//   subTitle: "Зачем нужна и как долго длится процесс",
			//   content: () => (
			//     <>
			//       <h2>Причины блокировки магазина</h2>
			//     </>
			//   ),
			// },
			{
				title: 'Причины блокировки карточек',
				subTitle: 'Какие бывают и как разблокировать карточку после блокировки',
				content: () => (
					<>
						<h2>Причины блокировки магазина</h2>
						<ol>
							<li>На качество товара поступают системные жалобы от покупателей;</li>
							<li>Описание товара не соответствует реальным характеристикам;</li>
							<li>Изображение товара не соответствует реальному виду;</li>
							<li>
								Аккаунт магазина заблокирован. <a href="#">Подробнее о причинах блокировки аккаунта</a>;
							</li>
							<li>В карточке товара содержится информация, запрещенная к публикации действующим
								законодательством.
							</li>
						</ol>
						{BlockInfo(
							'Причину блокировки можно будет увидеть в режиме редактирования карточки товара. После внесения изменений карточка будет повторно отправлена на модерацию.',
						)}
					</>
				),
			},
		],
	},
	{
		key: '3',
		label: 'Работа с заказами',
		bgColor: 'gray',
		items: [
			{
				title: 'Превращаем заказ в посылку',
				subTitle: 'Разбираемся со статусами заказов и почтовых отправлений здесь',
				content: () => (
					<>
						<h2>Превращаем заказ в посылку</h2>
						<p>
							Заказы покупателей мы с вами «превращаем» в почтовые отправления. Поэтому в разделе вы
							увидите ДВЕ ГРУППЫ
							статусов:
						</p>
						<ul>
							<li>статусы заказа (новые, отменены);</li>
							<li>статусы почтового отправления (ожидают отгрузки, доставляются, доставлены, отказ от
								получения).
							</li>
						</ul>
						<img src="/assets/images/faq/transform-orders.jpg" alt="Превращаем заказ в посылку"
						     className="" />
					</>
				),
			},
			{
				title: 'Новый заказ',
				subTitle: 'Что делать с новым, как отслеживать статусы и как не потерять заказ',
				content: () => (
					<>
						<h2>Новый заказ</h2>
						<p>
							Поступивший заказ отражается в ЛК, как «Новый». Вы принимаете заказ, когда нажимаете кнопку
							«Заказ
							собран».
						</p>
						{BlockInfo(
							'Обращаем ваше внимание. Вы не можете отменить заказ который уже приняли. Проверяйте качество и наличие товара заранее.',
						)}
						<p>
							<strong>Если проблем с товаром нет:</strong>
						</p>
						<ul>
							<li>Подтвердите готовность к отправке, нажав кнопку «Заказ собран».</li>
						</ul>
						{/*<p>*/}
						{/*	<strong>Если вы не можете собрать заказ частично (нет на остатке, выявлен брак и др.):</strong>*/}
						{/*</p>*/}
						{/*<ul>*/}
						{/*	<li>Выделите проблемные товары и нажмите кнопку «Удалить из заказа»;</li>*/}
						{/*	<li>Если товаров несколько, укажите количество для удаления;</li>*/}
						{/*	<li>Удаленный товар можно вернуть в заказ кнопкой «Вернуть в заказ»;</li>*/}
						{/*	<li>Подтвердите готовность к отправке, нажав кнопку «Заказ собран».</li>*/}
						{/*</ul>*/}
						{/*<p>*/}
						{/*	<strong>Если вы не можете собрать заказ полностью (нет на остатке, выявлен брак и др.):</strong>*/}
						{/*</p>*/}
						{/*<ul>*/}
						{/*	<li>Нажмите кнопку «Отменить заказ».</li>*/}
						{/*</ul>*/}
						<p>
							<strong>Кнопка «Заказ собран»:</strong>
						</p>
						<ul>
							<li>Запускает автоматическую генерацию адресных ярлыков для скачивания;</li>
							<li>Запускает процесс присвоения каждому отправлению трек-номера;</li>
							<li>Закрывает всякую возможность добавлять или возвращать товары в заказ.</li>
						</ul>
						<h3>Следите за временем</h3>
						<p>
							В разделе <a href="#">Настройки → Календарь</a> отгрузок вы указали скорость обработки
							заказов. Мы
							учитываем внесенные данные и показываем вам оставшееся время для обработки заказа.
						</p>
						{GetImage('calendar.png')}
						<p>
							Если остаток времени до передачи заказа в ОПС отгрузки менее 2 часов – время будет выделено
							красным
							цветом. Если время истекло и заказ не принят в ОПС, произойдет автоматическая отмена заказа.
						</p>
						{GetImage('orders.png')}
					</>
				),
			},
			{
				title: 'Адресные ярлыки',
				subTitle: 'Где взять и что с ними делать',
				content: () => (
					<>
						<h2>Адресные ярлыки</h2>
						<p>
							Адресные ярлыки формируются к заказу автоматически и доступны для скачивания/печати в
							разделе «Заказы»,
							статус – «Ожидают отгрузки».
						</p>
						<p>
							Если заказ объемный и для него требуется несколько упаковок, система рассчитает количество
							ярлыков с
							учетом предельных допустимых габаритов/веса одной упаковки.
						</p>
						<p>
							Доступно два варианта адресных ярлыков: обычный адресный ярлык (для печати на обычном
							принтере) и
							термоярлык (для печати на термопринтере).
						</p>
					</>
				),
			},
			{
				title: 'Упаковываем товар',
				subTitle: 'Правила и рекомендации по упаковке',
				content: () => (
					<>
						<h2>Упаковываем товар</h2>
						<h3>Правила и рекомендации по упаковке</h3>
						<p>
							Так как доставка вашего товара – это, фактически, пересылка почтового отправления,
							необходимо учитывать
							особенности процесса транспортировки, а значит выбирать подходящую упаковку под каждый тип
							товара.
						</p>
						<p>
							<strong>Базовые правила упаковки:</strong>
						</p>
						<ul>
							<li>Упаковка должна защищать вложение от потери ценности;</li>
							<li>Упаковка не должна препятствовать обработке, сортировке, транспортировке отправлений;
							</li>
							<li>Упаковка должна соответствовать весу и габаритам вложения;</li>
							<li>
								Упаковка должна быть закрыта так, чтоб не было доступа к вложению и/или возможности
								случайного открытия
								упаковки.
							</li>
						</ul>
						<p>
							<strong>Есть 2 вида упаковки:</strong>
						</p>
						<ul>
							<li>Основная (нужна всегда);</li>
							<li>Дополнительная (нужна для пересылки хрупких вложений).</li>
						</ul>
						<p>
							<strong>Основная защитная упаковка:</strong>
						</p>
						<ul>
							<li>
								Пакет с воздушно-пузырчатой пленкой – предназначен для упаковки отправлений весом до 2
								кг, которым не
								требуется защита от деформации;
							</li>
							<li>
								Курьерский пакет – пластичная упаковка из прочного полиэтилена, применяемая для отправки
								небольших
								посылок, которым не требуется защита от деформации;
							</li>
							<li>
								Коробка – различных размеров из плотного картона, фанеры, полимеров, пенопласта, которые
								применяются для
								защиты отправлений от деформации;
							</li>
							<li>
								Деревянная обрешетка – жесткая упаковка, представляющая собой деревянный каркас и
								предназначающаяся для
								перевозки хрупких, бьющихся, тяжеловесных и громоздких отправлений, их защиты от
								повреждений.
							</li>
						</ul>
						<h3>Выбираем упаковку</h3>
						<p>
							Товары в прочной картонной заводской упаковке достаточно упаковать в стрейтч плёнку. Для
							других товаров
							выберите один из вариантов упаковки:
						</p>

						{/* table */}
						{BlockInfo(
							'Если у вас возникнут сложности с упаковкой – мы окажем вам услугу упаковки прямо в момент приема товара для транспортировки.',
						)}
						{BlockInfo(
							'Если у вас возникнет необходимость закупки упаковки – обратитесь к специалисту в любом отделении Европочты.',
						)}
						<h3>Хрупкие отправления</h3>
						<p>
							Если ваш товар относится к категории «Хрупкое», обязательно используйте дополнительную
							упаковку помимо
							основной: поролон, воздушно-пузырчатую пленку, пенопласт, гофрокартон, иной наполнитель,
							размещенный таким
							образом, чтобы не допустить трения и перемещения между самими предметами, либо между
							предметами и стенками
							коробки.
						</p>
						<p>Рекомендованное количество дополнительного упаковочного материала:</p>
						<ul>
							<li>не менее 5 см между отдельными предметами вложения и между предметами и стенками
								коробки;
							</li>
							<li>в случае использования воздушно-пузырчатой пленки - не менее 4 слоёв.</li>
						</ul>
						<p>
							После осмотра при получении мы дополнительно промаркируем коробку специальным скотчем с
							отметкой
							«Хрупкое».
						</p>
						<p>
							<strong>К хрупким товарам относятся:</strong>
						</p>
						<ul>
							<li>Товары из пластмассы, игрушки;</li>
							<li>Небольшие аквариумы, клетки, переноски для животных;</li>
							<li>
								Посуда и изделия из/с содержанием стекла, хрусталя, керамики, фарфора, фаянса, дерева
								или металла;
							</li>
							<li>
								Предметы интерьера: люстры, лампы, светильники, вазы, картины, панно, зеркала,
								скульптуры, фоторамки,
								часы, кубки, статуэтки;
							</li>
							<li>Сантехника: из/с содержанием стекла, хрусталя, керамики, фарфора, фаянса;</li>
							<li>
								Пищевые и непищевые продукты в стеклянной таре (консервация, соки, медицинские
								препараты, ветеринарные
								препараты и т.д.), лабораторная посуда;
							</li>
							<li>Парфюмерия, косметика;</li>
							<li>Кофе и чай в стекле;</li>
							<li>Керамические ножи без индивидуальной упаковки;</li>
							<li>
								Электроника, например, телефоны, смартфоны, планшеты, коммуникаторы, регистраторы,
								навигаторы, жёсткие
								диски, электронные книги, цифровые фоторамки.
							</li>
						</ul>
						{BlockInfo(
							'Товар не должен свободно перемещаться внутри коробки: заполните свободное место бумагой или другим уплотнителем.',
						)}
						{BlockInfo(
							'Если вы НЕ выполнили правила отгрузки хрупких товаров: сотрудником Европочты не был произведен осмотр хрупкого вложения, на упаковку не был размещен ярлык "Хрупкое" - при повреждении товара во время транспортировки ущерб не возмещается.',
						)}
					</>
				),
			},
			{
				title: 'Отмененный заказ',
				subTitle: 'Виды отмен и какие у них последствия',
				content: () => (
					<>
						<h2>Отмененный заказ</h2>
						<p>
							Поступивший заказ отражается в ЛК, как «Новый». Вы принимаете заказ, когда нажимаете кнопку
							«Заказ
							собран».
						</p>
						<h3>Отмена продавцом</h3>
						<p>
							Если остаток времени до передачи заказа в ОПС отгрузки менее 2 часов – время будет выделено
							красным
							цветом. Если время истекло и заказ не принят в ОПС, произойдет автоматическая отмена заказа.
						</p>
						<p>Последствия:</p>
						<ul>
							<li>Покупатель получит уведомление об отмене;</li>
						</ul>
						<h3>Отмена площадкой</h3>
						<p>
							В личном кабинете вы можете также видеть заказы, которые отменены площадкой по следующим
							причинам:
						</p>
						<ul>
							<li>Клиент связался с нами и попросил отменить заказ до прибытия его на ОПС доставки.</li>
							<li>Клиент не забрал посылку в течении 10 дней. Мы ставим посылку на возврат.</li>
						</ul>
						{GetImage('orders.png')}
						<p>Последствия:</p>
						<ul>
							<li>Покупатель получит уведомление об отмене;</li>
						</ul>
						<h3>Отмена покупателем</h3>
						<p>
							Покупатель может отменить заказ в любой момент до его получения. Отказ от товара в момент
							получения (не
							устроил товар) также квалифицируется как отмена заказа.
						</p>
						<p>Последствия:</p>
						<ul>
							<li>
								Товар будет отправлен назад в ОПС отгрузки, откуда его нужно будет забрать. При возврате
								товара
								продавцом оплачивается «возвратный тариф» (50% от прямого тарифа на доставку).
							</li>
						</ul>
					</>
				),
			},
			// {
			//   title: "Возврат товара",
			//   subTitle: "Неприятно, но бывает. Детали процесса здесь",
			//        //   content: () => (
			//     <>
			//       <h2>Причины блокировки магазина</h2>
			//     </>
			//   ),
			// },
			{
				title: 'Изменение ФИО получателя',
				subTitle: '',
				content: () => (
					<>
						<h2>Изменение ФИО получателя</h2>
						<p>
							Если вы обнаружили, что в заказе указаны неправильные или неполные ФИО покупателя, не
							отменяйте заказ. Продолжайте обработку и отгрузку заказа. Данные покупателя будут
							скорректированы во время получения товара. Мы отправим вам форму заявления на изменение
							данных принимающего лица. Необходимо будет её заполнить и отправить нам его скан. После
							получения формы, посылка будет выдана получателю. Если у вас возникнут дополнительные
							вопросы по этой процедуре, пожалуйста, свяжитесь с нами для получения дополнительной
							информации.
						</p>
					</>
				),
			},
		],
	},
	{
		key: '4',
		label: 'Документооборот и взаиморасчёты',
		bgColor: 'blue',
		items: [
			{
				title: 'Два публичных договора и ни одной накладной',
				subTitle: 'Здесь описано, какие документы нужны, чтобы продавать на площадке',
				content: () => (
					<>
						<h2>Два публичных договора и ни одной накладной</h2>
						<p>
							Для того, чтобы продавать на площадке emall.by необходимо дать согласие с условиями двух
							публичных
							договоров:
						</p>
						<ul>
							<li>
								<a href={`${config.api.url}/emall_agreement.pdf`} target="_blank" rel="noreferrer">
									<strong>Договор присоединения</strong>
								</a>
								. Регулирует правила и условия продажи товаров продавца на площадке. На основании этого
								договора
								Продавец оплачивает вознаграждение.
							</li>
							<li>
								<a href={`${config.api.url}.by/postal_agreement.pdf`} target="_blank" rel="noreferrer">
									<strong>Договор оказания услуг почтовой связи</strong>
								</a>
								. Регулирует условия и правила использования сети отделений Европочта в качестве пунктов
								выдачи, а также
								условия и правила организации курьерской доставки товаров продавца. На основании этого
								договора Продавец
								оплачивает услугу доставки и выдачи товара покупателю.
							</li>
						</ul>
						<div className={cls.blockInfo}>
							<Icon name="info-20" />
							<span>
								Для присоединения к договорам нужно проставить соответствующие отметки на странице регистрации
							</span>
						</div>
						<p>
							Товар, заказанный покупателем, мы «превращаем» в почтовое отправление – и покупатель
							получает его как
							посылку в отделении Европочты либо с доставкой на дом. Именно поэтому в процессе нет ТТН.
						</p>
					</>
				),
			},
			{
				title: 'Получаем деньги за товар',
				subTitle: 'Как приходят деньги за заказы: куда, когда и сколько',
				img: 'coin.png',
				content: () => (
					<>
						<h2>Получаем деньги за товар</h2>
						<h3>Когда и куда?</h3>
						<p>
							Вы получаете на счёт сумму за товар в течение 1-3 дней после получения заказа покупателем. В
							личном
							кабинете статус заказа станет «Доставлен».
						</p>
						<h3>Сколько?</h3>
						<p>В зависимости от способа оплаты, который выбрал клиент:</p>
						<ul>
							<li>Зачисление 100% стоимости товара – если покупатель выбрал оплату при получении;</li>
							<li>
								Зачисление стоимости товара за вычетом суммы вознаграждения (4%) – если покупатель
								выбрал онлайн-оплату.
							</li>
						</ul>
					</>
				),
			},
			{
				title: 'Оплачиваем услугу доставки товара',
				subTitle: 'Здесь про единоличные акты и реестр оказанных услуг ',
				content: () => (
					<>
						<h2>Оплачиваем услугу доставки товара</h2>
						<p>
							Оплата услуг доставки и выдачи товаров производится на основании единоличных актов оказанных
							услуг. Для формирования такого акта, воспользуйтесь реестром услуг, который будет
							автоматически загружен в раздел Личного кабинета "Настройки" в блок "Финансы".
						</p>
						<p>
							Загрузка реестра услуг (комиссия маркетплейса) в Личный кабинет - не позднее 7 числа месяца,
							следующего за отчётным. Оплата услуг - не позднее 10 числа месяца, следующего за отчётным.
						</p>
						<p>
							Реестр за услуги доставки вы будете получать на почту указанную во время регистрации.
						</p>
					</>
				),
			},
			{
				title: 'Оплачиваем вознаграждение за продажи на площадке',
				subTitle: 'Два варианта: выплата и удержание – почитайте, чтобы знать разницу',
				content: () => (
					<>
						<h2>Оплачиваем вознаграждение за продажи на площадке</h2>
						<p>
							Оплата вознаграждения за пользование площадкой производится двумя способами, в зависимости
							от способа
							оплаты, который выбрал клиент:
						</p>
						<ul>
							<li>Оплата по единоличному акту – если покупатель выбрал оплату при получении;</li>
							<li>
								Удержание суммы вознаграждения (4%) в момент перечисления суммы за товар на счёт
								продавца – если
								покупатель выбрал онлайн-оплату.
							</li>
						</ul>
						<p>
							Для формирования единоличного акта, воспользуйтесь реестром услуг, который будет
							автоматически загружен в раздел Личного кабинета "Настройки" в блок "Финансы".
						</p>
						<p>
							Загрузка реестра услуг в Личный кабинет – не позднее 7 числа месяца, следующего за отчётным.
							Оплата услуг
							- не позднее 10 числа месяца, следующего за отчётным.
						</p>
					</>
				),
			},
		],
	},
	{
		key: '5',
		label: 'Обратная связь',
		bgColor: 'gray',
		items: [
			{
				title: 'Если что-то непонятно по процессам',
				subTitle: 'И в Базе знаний вы не смогли найти ответов или решений',
				content: (openModalFeedback) => (
					<>
						<h2>Если что-то непонятно по процессам</h2>
						<p>
							В Базе знаний мы постарались описать, как здесь всё устроено, поэтому наша рекомендация –
							внимательно
							ознакомиться со всеми разделами – ответы на большинство вопросов уже есть.
						</p>
						<p>
							Если всё же что-то осталось непонятным, к вашим услугам <strong>Команда заботы о
							партнерах</strong>, с
							которой можно связаться несколькими способами:
						</p>
						<ul>
							<li>
								Через кнопку <a onClick={() => openModalFeedback && openModalFeedback(true)}>Связаться с
								нами</a>. Здесь
								вы сможете детализировать вопрос - и он быстрее попадет к тому, кто специализируется
								именно на данной
								теме. Мы поставили себе цель – ответить вам в течение 24 часов, но если получится – то и
								быстрее.
							</li>
							<li>
								Через письмо на почту <a href="mailto:support@emall.by">support@emall.by</a>. Ваш вопрос
								будет направлен
								в нужные руки, но это будет чуть дольше.
							</li>
						</ul>
					</>
				),
			},
			{
				title: 'Отзывы и комментарии, жалобы и предложения',
				subTitle: 'Как обрабатываем, зоны ответственности и какие правила публикации комментариев',
				content: () => (
					<>
						<h2>Отзывы и комментарии, жалобы и предложения</h2>
						<p>У покупателя есть 2 способа поделиться обратной связью:</p>
						<ul>
							<li>Комментарий/отзыв к товару;</li>
							<li>Форма обратной связи через кнопку «Связаться с нами».</li>
						</ul>
						{BlockInfo('Вся обратная связь от покупателя обрабатывается Контакт-центром emall.')}
						<p>
							Если обращение покупателя связано с вашим товаром, то мы свяжемся с вами для выяснения
							деталей и
							подготовки ответа покупателю. Контакт для связи и время, когда вы готовы обрабатывать
							вопросы покупателей,
							вы указали при регистрации, также они отображаются в <a href="#">Настройки → Информация о
							продавце</a>
						</p>
						{GetImage('infoSeller.png')}
						<p>
							Ваша задача – предоставить всю информацию, которую запрашивает Контакт-центр emall для
							обработки
							обращения. Итоговую обратную связь покупателю дает Контакт-центр emall.
						</p>
						<p>
							<strong>Когда можно оставить отзыв:</strong>
						</p>
						<ul>
							<li>Отзыв можно оставить только на товар, который был получен покупателем;</li>
							<li>С момента получения товара должно пройти не более 365 дней;</li>
							<li>На товар можно оставить один отзыв после заказа;</li>
							<li>
								Отзыв может быть в виде текстового комментария и/или в виде оценки (рейтинг с
								пятибалльной шкалой).
							</li>
						</ul>
						<p>
							<strong>Когда мы не публикуем отзыв:</strong>
						</p>
						<ul>
							<li>Отзыв относится к другому товару;</li>
							<li>Отзыв содержит нецензурную лексику или угрозыl;</li>
							<li>Отзыв содержит прикрепленные нецензурные фото;</li>
							<li>Есть факты, подтверждающие, что негативный отзыв умышленно опубликован конкурентом.</li>
						</ul>
					</>
				),
			},
			// {
			//   title: "Следим за новостями и обновлениями",
			//   subTitle:
			//     "Как не пропустить появление новых функций и быть в курсе событий",
			//   content: () => (
			//     <>
			//       <h2>Следим за новостями и обновлениями</h2>
			//       <p>
			//         Текущая версия торговой площадки и функции Личного кабинета
			//         продавца – это базовая версия. Мы уже работаем над инструментами,
			//         которые позволят вам стимулировать свои продажи и продвигать
			//         товары, которые сделают еще удобнее процесс комплектации и
			//         отправки товаров покупателю.
			//       </p>
			//       <p>
			//         Всё это и многое другое мы планируем постепенно выгружать в
			//         ближайшие месяцы.
			//       </p>
			//       <p>Чтобы быть в курсе событий:</p>
			//       <ul>
			//         <li>
			//           Читайте блок новостей на главной странице Личного кабинета;
			//         </li>
			//         <li>Читайте e-mail рассылки;</li>
			//         <li>
			//           Подпишитесь на официальные аккаунты emall-seller в{" "}
			//           <a href="#">Instagram</a> и <a href="#">Telegram</a>.
			//         </li>
			//       </ul>
			//     </>
			//   ),
			// },
		],
	},
];

export default dataFAQ;
